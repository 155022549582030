<template>
  <section>
    <customer-detail-main />
  </section>
</template>

<script>
export default {
  name: 'CustomerDetail',
  components: {
    CustomerDetailMain: () => import('@/components/Customers/Detail/CustomerDetailMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
